import axios from "axios";

import appSettings from "../../../config/config";
const apiUrl = appSettings.apiUrl


const getApiUrl = (partnerAccountId) => {
  if (partnerAccountId == "npHcOKGvfrymnVrdrh0k") return appSettings.ejmalyApiUrl
  return apiUrl || partnerAccountId
}

const getWhitelabelBusinesses = async (wlId) => {
  const response = await axios.get(
    `${getApiUrl(wlId)}/api/partner/wl/${wlId}/businesses`
  );
  return response.data.businesses || [];
};

const getResellerBusinesses = async (resellerAccId) => {
  const response = await axios.get(
    `${getApiUrl(resellerAccId)}/api/partner/reseller/${resellerAccId}/businesses`
  );
  return response.data.businesses || [];
};

const linkUserBusinesses = async (partnerAccountId, email) => {
  await axios.post(
    `${getApiUrl(partnerAccountId)}/api/partner/${partnerAccountId}/link-user-businesses`,
    {
      email: email,
    }
  );
};

const updateBusinessToPremium = async (wlId, businessDetails, period) => {
  await axios.post(
    `${getApiUrl(wlId)}/api/partner/wl/${wlId}/business/${businessDetails.id}/subscribe`,
    {
      pricingSegmentId: businessDetails.pricingSegmentId,
      period: period
    }
  );
};

const subscribeViaResellerRecharge = async (resellerId, businessDetails) => {
  await axios.post(
    `${getApiUrl(resellerId)}/api/partner/reseller/${resellerId}/business/${businessDetails.id}/subscribeViaRecharge`,
    {
      pricingSegmentId: businessDetails.pricingSegmentId,
    }
  );
};

// Send stripe link
const getPremiumLinkForBusiness = async (partnerAccountId, businessDetails) => {
  const response = await axios.post(
    `${getApiUrl(partnerAccountId)}/api/partner/${partnerAccountId}/business/${businessDetails.id}/create-premium-link`,
    {}
  );
  return response.data.link;
};

export default {
  getResellerBusinesses,
  getWhitelabelBusinesses,
  linkUserBusinesses,
  updateBusinessToPremium,
  getPremiumLinkForBusiness,
  subscribeViaResellerRecharge
};
