<template>
  <div class="partner-businesses">
    <div class="app-loader" v-if="businessFetchWaiting">
      <span class="app-loader__message">Loading your businesses...</span>
      <v-progress-circular
        class="mx-auto"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row v-else-if="businessFetchError">
      <v-alert style="width: 100%" type="error">
        Could not fetch the business list. {{ businessFetchError }}
      </v-alert>
    </v-row>
    <div v-else>
      <v-row>
        <v-col col="12">
          <div class="actions">
            <add-business-dialog v-if="!isWhitelabel && false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on"
                  ><v-icon>mdi-plus</v-icon> Add business</v-btn
                >
              </template>
            </add-business-dialog>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              All Businesses
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="getTableItems()"
              :server-items-length="businessCount"
              :loading="businessFetchWaiting"
              :options.sync="options"
              item-key="id"
              :footer-props="{
                'items-per-page-options': [100],
              }"
              class="business-table"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-btn
                  class="text-none text-left px-0"
                  style="letter-spacing: normal; justify-content: left; text-decoration: underline;"
                  text
                  :to="'/partner/business/' + item.id"
                >
                  {{ item.name }}
                </v-btn>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div @click="openEmail(item.email)">
                  {{ item.email }}
                </div>
              </template>
              <template v-slot:[`item.phone`]="{ item }">
                <div @click="openPhone(item.phone)">
                  {{ item.phone }}
                </div>
              </template>
              <template v-slot:[`item.plan`]="{ item }">
                <div>
                  <div class="no-wrap">
                    {{ getPlanName(item) }}
                  </div>

                  <div class="text-small" v-if="item.activePlanExpiry">
                    <span class="no-wrap">
                      exp:
                      {{ getFormattedDate(item.activePlanExpiry) }}
                    </span>
                    <span v-if="item.isSubscriptionLinked == false">
                      Already Subscribed
                    </span>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                <div class="no-wrap">
                  {{ item.created }}
                </div>
              </template>
              <template v-slot:[`item.linkedAt`]="{ item }">
                <div class="no-wrap">
                  {{ item.linkedAt }}
                </div>
              </template>
            </v-data-table>
            <!-- <v-simple-table class="businesses-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Business Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Plan</th>
                    <th class="text-left">Created</th>
                    <th class="text-left">Linked at</th>
                    <th class="text-left"># Receipts</th>
                    <th># Items</th>
                    <th># Staff</th>
                    <th class="text-left">Total Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="business in businessList" :key="business.id">
                    <td class="text-left">
                      <v-btn
                        class="text-none text-left px-0"
                        style="letter-spacing: normal;justify-content:left"
                        text
                        :to="'/partner/business/' + business.id"
                      >
                        {{ business.name }}
                      </v-btn>
                    </td>
                    <td @click="openEmail(business.email)">
                      {{ business.email }}
                    </td>
                    <td @click="openPhone(business.phone)">
                      {{ business.phone }}
                    </td>
                    <td class="text-left">
                      <div>
                        <div>
                          {{ getPlanName(business) }}
                        </div>

                        <div
                          class="text-small"
                          v-if="business.activePlanExpiry"
                        >
                          <span class="no-wrap">
                            exp:
                            {{ getFormattedDate(business.activePlanExpiry) }}
                          </span>
                          <span>
                            {{ business.isAutoRenewing ? "Autorenewing" : "" }}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td class="no-wrap">
                      {{ getFormattedDate(business.createdDate) }}
                    </td>
                    <td class="no-wrap">{{ getFormattedDate(new Date()) }}</td>
                    <td>{{ business.receiptCount }}</td>
                    <td>{{ business.itemCount }}</td>
                    <td>{{ business.staffCount }}</td>
                    <td class="text-center">
                      <div v-if="business.totalAmount">
                        {{ business.currencyCode }} {{ business.totalAmount }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import AddBusinessDialog from "../../components/AddBusinessDialog.vue";

const getHeaders = (isWhitelabel) => {
  if (isWhitelabel) {
    return [
      {
        text: "Business Name",
        align: "start",
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Plan", value: "plan" },
      { text: "Created", value: "created" },
      { text: "Linked At", value: "linkedAt" },
      { text: "# Receipts", value: "receiptCount" },
      { text: "# Items", value: "itemCount" },
      { text: "# Staff", value: "staffCount" },
      { text: "Total Value", value: "totalValue" },
      { text: "Is Blocked", value: "isBlocked" },
    ];
  } else {
    return [
      {
        text: "Business Name",
        align: "start",
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Plan", value: "plan" },
      { text: "Created", value: "created" },
      { text: "Address", value: "address" },
      { text: "type", value: "type" },
      { text: "# Receipts", value: "receiptCount" },
      { text: "# Items", value: "itemCount" },
      { text: "# Staff", value: "staffCount" },
      { text: "Total Value", value: "totalValue" },
    ];
  }
};

const getFormattedDate = (date) => {
  return date ? moment(date).format("ll") : "-";
};

const getTableItem = (business, isWhitelabel) => {
  if (isWhitelabel) {
    return {
      id: business.id,
      name: business.name,
      email: business.email,
      phone: business.phone,
      plan: business.activePlanId,
      created: getFormattedDate(business.createdDate),
      linkedAt: getFormattedDate(new Date()),
      receiptCount: business.receiptCount,
      itemCount: business.itemCount,
      staffCount: business.staffCount,
      totalValue: business.totalAmount,
      type: business.type,
      address: business.address,
      isAutoRenewing: business.isAutoRenewing,
      activePlanExpiry: business.activePlanExpiry,
      activeProductId: business.activeProductId,
      isBlocked: business.isBlocked,
    };
  } else {
    return {
      id: business.id,
      name: business.name,
      email: business.email,
      phone: business.phone,
      plan: business.activePlanId,
      created: getFormattedDate(business.createdDate),
      linkedAt: getFormattedDate(new Date()),
      receiptCount: business.receiptCount,
      itemCount: business.itemCount,
      staffCount: business.staffCount,
      totalValue: business.totalAmount,
      isAutoRenewing: business.isAutoRenewing,
      activePlanExpiry: business.activePlanExpiry,
      activeProductId: business.activeProductId,
    };
  }
};

export default {
  components: {
    AddBusinessDialog,
  },
  data() {
    return {
      options : {
        sortDesc: false,
        sortBy: [],
        page: 1,
        itemsPerPage: 100,
        multiSort: false,
        mustSort: false,
        groupBy: [],
        groupDesc: [],
    }
    };
  },
  computed: {
    ...mapGetters({
      businessFetchWaiting: "partnerBusiness/businessFetchWaiting",
      businessFetchError: "partnerBusiness/businessFetchError",
      businessList: "partnerBusiness/businessList",
      activeAccount: "partner/active",
      businessCount: "partnerBusiness/businessCount",
      loadedBusinessList: "partnerBusiness/loadedBusinessList",
    }),
    isWhitelabel() {
      return this.activeAccount.type == "whitelabel";
    },
    headers() {
      return getHeaders(this.isWhitelabel);
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchBusinessesFromServer();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchBusinesses: "partnerBusiness/fetchBusinesses",
      setBusinessList: "partnerBusiness/setBusinessList",
    }),
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    openEmail(email) {
      window.open("mailto:" + email, "_blank");
    },
    openPhone(phone) {
      if (!phone) return;
      window.open("tel:" + phone);
    },
    getPlanName(business) {
      if (!business.activeProductId) return "No Plan";
      return (
        business.activeProductId +
        " - " +
        (business.activePlanId == "p1m" ? "monthly" : "yearly")
      );
    },
    getTableItems() {
      // fetching the data before we can use it
      this.fetchBusinessesFromServer();
      let items = [];
      for (let business of this.businessList) {
        items.push(getTableItem(business, this.isWhitelabel));
      }
      return items;
    },
    fetchBusinessesFromServer() {
      let bId = null;
      if(this.businessList.length) {
        bId = this.businessList[this.businessList.length-1].id
      }
      if(Object.prototype.hasOwnProperty.call(this.loadedBusinessList, this.options.page)) {
        return this.setBusinessList(this.loadedBusinessList[this.options.page]);
      }
      this.fetchBusinesses({
        activeAccount: this.activeAccount,
        bId,
        options: this.options,
      });
    },
  },
};
</script>

<style lang="scss">
.businesses {
  min-height: 100%;
}

.businesses-table {
  th {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-small {
  font-size: 0.7rem;
}
</style>
