<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add via email</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <v-text-field
                v-model="enteredEmail"
                :rules="rules"
                label="Enter user Email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="linkBusinesses">
          Link Businesses
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import partnerBusinessService from "../services/partner/business";

export default {
  data() {
    return {
      dialog: false,
      enteredEmail: "",
      notifications: false,
      sound: true,
      widgets: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
    }),
  },
  methods: {
    async linkBusinesses() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const email = this.enteredEmail;
      if (!pattern.test(email)) {
        return "Invalid Email";
      }
      this.dialog = false;
      // Call an api which will link all businesses of an email
      try {
        this.$notifier.showMessage({
          content: "Please wait while we link the user accounts",
        });
        await partnerBusinessService.linkUserBusinesses(
          this.activeAccount.id,
          email
        );
        this.$notifier.showMessage({
          content: "Accounts linked. Please reload the page.",
        });
      } catch (error) {
        this.$notifier.showMessage({
          content:
            "An error occurred while linking user account. " + error.message,
        });
      }
    },
  },
};
</script>
